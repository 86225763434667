body {
  margin: 0;
}

.App {
  text-align: center;
}

.App-logo {
  width: 260px;
  margin-bottom: 60px;
}

.App-header {
  background-image: url("./images/main_bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #fff;
  font-size: 24px;
  width: 260px;
  background: linear-gradient(90.13deg, #00317B 0%, #720066 100%), #8A8A8A;
  border-radius: 5px;
  text-decoration: none;
  padding: 20px 40px;
  letter-spacing: 1px;
}

@media only screen and (max-width: 1440px) {
  .App-header {
    background-image: url("./images/main_bg_laptop.jpg");
  }

  .App-logo {
    width: 220px;
    margin-bottom: 40px;
  }

  .App-link {
    width: 220px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 599px) {
  .App-header {
    background-image: url("./images/main_bg_mob.jpg");
  }

  .App-logo {
    width: 140px;
  }

  .App-link {
    width: 140px;
    font-size: 16px;
  }
}
